import React from 'react';
import Swal from 'sweetalert2'; // Import SweetAlert2
import '../../assets/css/Projects.css'; // Import file CSS
import CSS from '../../assets/images/CSS.jpeg';
import HTML from '../../assets/images/HTML.jpeg';
import JS from '../../assets/images/JS.png';

const Projects = () => {
  window.scrollTo(0, 0);
  // Fungsi untuk menampilkan SweetAlert ketika project diklik
  const handleProjectClick = (projectName) => {
    Swal.fire({
      icon: 'info',
      title: `Pelajaran ${projectName} akan Datang!`,
      text: 'Website ini Masih dalam tahap Perkembangan, Mohon Tunggu Pembaruan Selanjutnya!',
      confirmButtonText: 'OK',
      confirmButtonColor: '#007bff',
    });
  };

  return (
    <section id='divisi' className="project-section animate__animated animate__fadeIn">
      <h2>Pembagian Bahasa Pemrograman Web Developer</h2>
      <div className="project-container">
        <div className="project-card" onClick={() => handleProjectClick('HTML')}>
          <img src={HTML} alt="Project 1" className="img-fluid"/>
          <div className="card-body">
            <h3 className="card-title">HTML</h3>
            <p className="card-text">
            Divisi Programming yang Bertanggung Jawab dalam Pembuatan dan Pengembangan Website tersebut.
            </p>
          </div>
        </div>

        <div className="project-card" onClick={() => handleProjectClick('CSS')}>
          <img src={CSS} alt="Project 2" className="img-fluid"/>
          <div className="card-body">
            <h3 className="card-title">CSS</h3>
            <p className="card-text">
            Multimedia Divisi ini Berfokus kepada desain grafis, animasi, dan produksi konten
            </p>
          </div>
        </div>

        <div className="project-card" onClick={() => handleProjectClick('JavaScript')}>
          <img src={JS} alt="Project 3" className="img-fluid"/>
          <div className="card-body">
            <h3 className="card-title">JavaScript</h3>
            <p className="card-text">
            E-Sports yang Berfokus kepada Kompetisi Game.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
