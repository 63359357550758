import React from 'react';
import Header from '../components/Header'; 
import Home from '../components/Programming/Body';
import Pembagian from '../components/Programming/Pembagian'
import Footer from '../components/Footer';

const ProgPage = () => {
  return (
    <div>
      <Header />
      <main>
        <div className='head'>
          <Home/>
        </div>
        <div className='divisi'>
          <Pembagian />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ProgPage;
