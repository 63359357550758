import React from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Import SweetAlert2
import '../assets/css/Projects.css'; // Import file CSS
import Programming from '../assets/images/Programming2.jpg';
import Multimedia from '../assets/images/Multimedia.jpg';
import Esoprt from '../assets/images/Esport.jpg';

const Projects = () => {
  const navigate = useNavigate();

  const ProgPage = () => {
    navigate('/programming#home');
  };

  // Fungsi untuk menampilkan SweetAlert ketika project diklik
  const handleProjectClick = (projectName) => {
    Swal.fire({
      icon: 'info',
      title: `Divisi ${projectName} akan Datang!`,
      text: 'Website ini Masih dalam tahap Perkembangan, Mohon Tunggu Pembaruan Selanjutnya!',
      confirmButtonText: 'OK',
      confirmButtonColor: '#007bff',
    });
  };

  return (
    <section id='projects' className="project-section">
      <h2>Divisi Ekstrakulikuler OPTION 10</h2>
      <div className="project-container">
        <div className="project-card" onClick={ProgPage}>
          <img src={Programming} alt="Project 1" loading="lazy" className="img-fluid"/>
          <div className="card-body">
            <h3 className="card-title">Programming</h3>
            <p className="card-text">
            Divisi Programming yang Bertanggung Jawab dalam Pembuatan dan Pengembangan Website tersebut.
            </p>
          </div>
        </div>

        <div className="project-card" onClick={() => handleProjectClick('MultiMedia')}>
          <img src={Multimedia} alt="Project 2" loading="lazy" className="img-fluid"/>
          <div className="card-body">
            <h3 className="card-title">MultiMedia</h3>
            <p className="card-text">
            Multimedia Divisi ini Berfokus kepada desain grafis, animasi, dan produksi konten
            </p>
          </div>
        </div>

        <div className="project-card" onClick={() => handleProjectClick('E-Sports')}>
          <img src={Esoprt} alt="Project 3" loading="lazy" className="img-fluid"/>
          <div className="card-body">
            <h3 className="card-title">E-Sports</h3>
            <p className="card-text">
            E-Sports yang Berfokus kepada Kompetisi Game.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
