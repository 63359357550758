import React from 'react';
import 'animate.css'; // Import animate.css
import '../assets/css/Home.css'
import homePic from '../assets/images/homePic.png'; // Import gambar

const Home = () => {
  return (
    <section id='home'
      className="home-section animate__animated animate__fadeIn" // Animasi fadeIn untuk seluruh section
      style={{
        backgroundColor: '#1b1b1b', // Warna background gelap
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        paddingTop: '100px', // Tambahkan lebih banyak padding untuk menghindari header
      }}
    >
      <div className="container">
        <div className="row align-items-center"> {/* Atur elemen dalam flexbox atau grid */}
          <div className="col-md-6 text-center text-md-start"> {/* Text di layar kecil di tengah */}
          <h1 className="display-4 animate__animated animate__fadeInDown animate__delay-1s" style={{ fontFamily: 'Poppins, sans-serif' }}>
            Official Practice of Technology Information and Communication
          </h1>
            <p className="lead animate__animated animate__fadeInUp animate__delay-1s" style={{ fontFamily: 'Raleway, sans-serif' }}>
              Ekstrakulikuler yang berada di <a href='https://www.sman10bekasi.sch.id/'>SMA Negeri 10 BEKASI</a> yang berfungsi sebagai wadah untuk murid yang memiliki minat bakat di bidang Teknologi. <b style={{ fontWeight: 'bold' }}>#AlwaysOnNeverOFF #SalamIT</b>
            </p>
            <a 
              href="#about" 
              className="btn btn-outline-primary btn-lg mt-3 animate__animated animate__fadeInUp animate__delay-1s" // Animasi bounce pada tombol
            >Informasi Lebih Lanjut</a>
          </div>
          <div className="col-md-6 text-center"> {/* Gambar di tengah pada layar kecil */}
            <img 
              src={homePic} 
              loading="lazy"
              alt="Illustration" 
              className="img-fluid animate__animated animate__fadeInRight animate__delay-1s"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
